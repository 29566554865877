import useTranslator from '~/lib/useTranslator'
import DownloadButtons from './DownloadButtons'

export default function GetStarted() {
  const Translator = useTranslator()

  return (
    <div className="relative max-w-screen-xl mx-auto py-10 rounded-xl bg-[url('/images/banner-bg.svg')] bg-cover bg-no-repeat bg-right-top bg-premium-color">
      <div
        className="absolute top-0 right-0 w-1/2 h-full bg-[url('/images/fotballer.svg')] bg-center hidden lg:block bg-no-repeat"
        style={{ backgroundSize: 330, backgroundPositionX: 50, backgroundPositionY: -50 }}
      />
      <div className="lg:w-3/5 px-10 items-center lg:items-start flex flex-col">
        <h4 className="lg:text-4xl leading-snug lg:leading-normal text-white font-bold">
          {Translator.singular('Join {{ noOfTeams }} other teams in switching to Heja', {
            noOfTeams: '350,000+',
          })}
        </h4>
        <p className="text-white mt-4">
          {Translator.singular('Get going in minutes and invite your whole team.')}
        </p>
        <div className="mt-12">
          <DownloadButtons />
        </div>
      </div>
    </div>
  )
}
